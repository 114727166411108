<template>
	<ZyroLoader
		v-if="isLoadingFonts"
		class="loader"
	/>
	<div v-else>
		<div
			v-for="fontType in fontTypes"
			:key="fontType"
			class="fonts"
		>
			<ZyroLabel>
				{{ label || capitalizeFirstLetter(fontType) }}
			</ZyroLabel>
			<div class="native-select">
				<select
					v-qa="`builder-globalstyles-typography-fontselector-${fontType}`"
					required
					class="z-body-small"
					:name="fontType"
					@change="setFont(JSON.parse($event.target.value), fontType)"
				>
					<option
						v-for="font in fonts"
						:key="font.family"
						:value="JSON.stringify(font)"
						:selected="extractFontName(website.styles.font[fontType]) === font.family"
						data-qa="builder-globalstyles-typography-fontlist"
					>
						{{ font.family }}
					</option>
				</select>
				<ZyroSvg
					class="select-arrow"
					name="chevron-down-small"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import {
	mapState,
	mapMutations,
	mapGetters,
} from 'vuex';

import {
	FONT,
	PROPERTY_FONT_WEIGHT,
	FONT_WEIGHT_ELEMENTS,
	PROPERTY_FONT_FAMILY,
	PROPERTY_PRIMARY_FONT,
	PROPERTY_SECONDARY_FONT,
} from '@/constants/globalStyles';
import {
	mapActionsFonts,
	mapStateFonts,
	UPDATE_FONT_STYLES,
} from '@/store/builder/fonts';
import { getClosestNumberInArray } from '@/utils/array';
import {
	constructFontForCSS,
	convertWeightStringToNumber,
	extractFontName,
	extractFontTypeFromVariable,
} from '@/utils/font';
import { capitalizeFirstLetter } from '@/utils/modifyString';

const FONTS = [
	PROPERTY_PRIMARY_FONT,
	PROPERTY_SECONDARY_FONT,
];

export default {
	props: {
		fontTypes: {
			type: Array,
			default: () => FONTS,
		},
		label: {
			type: String,
			default: null,
		},
	},
	setup() {
		return {
			extractFontName,
			capitalizeFirstLetter,
		};
	},
	computed: {
		...mapStateFonts([
			'isLoadingFonts',
			'fonts',
		]),
		...mapGetters('fonts', ['getAvailableFontWeights']),
		...mapState(['website']),
	},
	methods: {
		...mapMutations([
			'setStyleProperty',
			'setWebsiteMeta',
		]),
		...mapActionsFonts({ updateFontStyles: UPDATE_FONT_STYLES }),
		setFont({
			family,
			category,
		}, type) {
			this.updateFontStyles({
				family,
				type,
			});
			this.setGlobalStyleFont(family, category, type);
			this.replaceUnavailableFontWeights(type);
			this.$emit('font-change', {
				family,
				type,
			});
		},
		setGlobalStyleFont(family, category, type) {
			this.setStyleProperty({
				element: FONT,
				property: type,
				value: constructFontForCSS(family, category),
			});
		},
		replaceUnavailableFontWeights(type) {
			const { styles } = this.website;

			FONT_WEIGHT_ELEMENTS.forEach((element) => {
				const styleElement = styles[element];
				// TODO remove after a mapper is complete
				const elementFontWeight = convertWeightStringToNumber(
					styleElement[PROPERTY_FONT_WEIGHT],
				);
				const elementFontType = extractFontTypeFromVariable(
					styleElement[PROPERTY_FONT_FAMILY],
				);

				const closestFontWeight = getClosestNumberInArray(
					this.getAvailableFontWeights[type], elementFontWeight,
				);

				if (elementFontType === type) {
					this.setStyleProperty({
						element,
						property: PROPERTY_FONT_WEIGHT,
						value: closestFontWeight,
					});
				}
			});
		},
	},
};
</script>

<style scoped lang="scss">
.fonts {
	margin-bottom: 16px;
}

.loader {
	margin-bottom: 24px;
}

.native-select {
	position: relative;
	margin: 8px 0 40px;

	.select-arrow {
		position: absolute;
		top: 20px;
		right: 22px;
		cursor: pointer;
		transition: transform 0.2s;
	}

	select {
		width: 100%;
		height: 45px;
		padding: 0 16px;
		cursor: pointer;
		background: $grey-100;
		border: none;
		appearance: none;

		&:hover,
		&:active {
			background: $grey-200;
		}

		&:focus,
		&:focus-within {
			border: $dark 1px solid;
			outline: none;
		}
	}
}
</style>
