<template>
	<div>
		<Fonts />
		<ZyroCollapse
			v-for="(props, element) in filteredTextStyles"
			:key="element"
			class="element"
			size="large"
		>
			<template #trigger>
				<h2 class="element__name z-body-small z-body-small--strong">
					{{ element }}
				</h2>
			</template>
			<div>
				<TextBoxPreview :element="element" />
				<TextControl :element="element" />
			</div>
		</ZyroCollapse>
	</div>
</template>
<script>
import {
	mapMutations,
	mapState,
} from 'vuex';

import TextControl
	from '@/components/builder-drawers/drawers/partials/globalStylesDrawer/controls/sections/TextControl.vue';
import Fonts from '@/components/builder-drawers/drawers/partials/globalStylesDrawer/typography/Fonts.vue';
import TextBoxPreview from '@/components/builder-drawers/drawers/partials/stylesMisc/TextBoxPreview.vue';

const TEXT_ELEMENTS = [
	'h1',
	'h2',
	'h3',
	'h4',
	'h5',
	'h6',
	'body-large',
	'body',
	'body-small',
	'nav-link',
];

export default {
	components: {
		TextBoxPreview,
		Fonts,
		TextControl,
	},
	computed: {
		...mapState(['website']),
		filteredTextStyles() {
			return Object.fromEntries(
				TEXT_ELEMENTS
					.map((textElement) => ([
						textElement,
						this.website.styles[textElement],
					])),
			);
		},
	},
	methods: {
		...mapMutations(['setStyleProperty']),
		elementToDataObject(element) {
			return {
				content: `<p class="${this.getClassName(element)}">Zyro</p>`,
				type: 'GridTextBox',
			};
		},
		getClassName(element) {
			// Key in json is h1, h2. Class name is h-1, h-2. Need to split from h1 to h-1
			if (element.charAt(0) !== 'h') {
				return element;
			}

			return `${element.split('')[0]}-${element.split('')[1]}`;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/components/builder-drawers/drawers/partials/_forms.scss';

.preview {
	padding: 0;
}

::v-deep {
	.text-box {
		padding: 24px;
	}
}

.element {
	border-top: 1px solid $grey-200;

	&__name {
		margin-right: 10px;
		text-transform: capitalize;
	}
}

</style>
