<template>
	<div class="preview">
		<GridTextBox>
			<h1 :class="className">
				Zyro
			</h1>
		</GridTextBox>
	</div>
</template>

<script>
import GridTextBox from '@user/components/grid-components/textBox/GridTextBox.vue';

export default {
	components: { GridTextBox },
	props: {
		element: {
			type: String,
			default: null,
		},
	},
	computed: {
		className: ({ element }) => {
			if (element.charAt(0) !== 'h') {
				return element;
			}

			return `${element.split('')[0]}-${element.split('')[1]}`;
		},
	},
};
</script>

<style scoped lang="scss">
@import '@/components/builder-drawers/drawers/partials/_forms.scss';
</style>
